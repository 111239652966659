import request from '@/utils/request'

// 查询推荐数据
export const searchCourse = (params) => {
  return request({
    method: 'GET',
    url: '/v2/course/getAllCourse',
    params: { ...params, status: 1 }
  })
}
